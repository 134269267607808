import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import './UnderConstructionAnimation.scss';
import Lottie from 'react-lottie';
import underConstructionAnimation from '../../../assets/lottie/under-construction-recolored.json';
class UnderConstructionAnimation extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: underConstructionAnimation,
            rendererSettings: {}
        };
        return (React.createElement(CSSTransition, { classNames: "loading-lottie", in: true, appear: true, timeout: 2000 },
            React.createElement("div", { className: 'LoadingLottie' },
                React.createElement(Lottie, { options: defaultOptions, width: '100%' }))));
    }
}
export default UnderConstructionAnimation;
