import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import './WorldSpinningAnimation.scss';
import Lottie from 'react-lottie';
import loadingAnimation from '../../../assets/lottie/world-spinning.json';
class WorldSpinningAnimation extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingAnimation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (React.createElement(CSSTransition, { classNames: "loading-lottie", in: true, appear: true, timeout: 2000 },
            React.createElement("div", { className: 'LoadingLottie' },
                React.createElement(Lottie, { options: defaultOptions, height: 400, width: 400 }))));
    }
}
export default WorldSpinningAnimation;
