import React from 'react';
import './App.scss';
import UnderConstructionPage from './components/page/UnderConstructionPage';
import WorldSpinningAnimation from './components/global/WorldSpinningAnimation';
class App extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            ready: false,
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                ready: true,
            });
        }, 4000);
    }
    render() {
        if (!this.state.ready) {
            return (React.createElement("div", { className: 'App', style: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' } },
                React.createElement(WorldSpinningAnimation, null)));
        }
        return (React.createElement("div", { className: "App" },
            React.createElement(UnderConstructionPage, null)));
    }
}
export default App;
