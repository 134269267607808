import React, { Component } from 'react';
import './UnderConstructionPage.scss';
import UnderConstructionAnimation from '../global/UnderConstructionAnimation';
// import logo from "../../../assets/images/logo.jpg";
import logo from '../../../assets/images/mobotiq_svg.svg';
import { CSSTransition } from 'react-transition-group';
class UnderConstructionPage extends Component {
    render() {
        return (React.createElement("div", { className: 'UnderConstructionPage row g-0' },
            React.createElement("div", { className: 'col-12 col-lg-4 uc-left-side g-0' },
                React.createElement("div", { className: 'animation-container' },
                    React.createElement(UnderConstructionAnimation, null)),
                React.createElement(CSSTransition, { classNames: "enter-scene-animation", in: true, appear: true, timeout: 2000 },
                    React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' } },
                        React.createElement("div", { className: 'working-hard-title', style: { fontVariant: 'small-caps' } }, "Under construction"),
                        React.createElement(CSSTransition, { classNames: "enter-scene-animation-long", in: true, appear: true, timeout: 2000 },
                            React.createElement("div", { className: 'working-hard-description' },
                                "We're putting all the pieces together.",
                                React.createElement("br", null),
                                "Coming soon."))))),
            React.createElement(CSSTransition, { classNames: "enter-scene-animation", in: true, appear: true, timeout: 4000 },
                React.createElement("div", { className: 'col-12 col-lg-8 uc-right-side g-0' },
                    React.createElement("div", { className: 'logo-container' },
                        React.createElement("img", { className: 'top-logo', src: logo }),
                        React.createElement("div", { className: 'logo-name' }, "Mobotiq")),
                    React.createElement(CSSTransition, { classNames: "enter-scene-animation-long", in: true, appear: true, timeout: 2000 },
                        React.createElement("div", { className: 'content-container' },
                            React.createElement("div", { className: 'content-description' }, "While we work to bring the best site to you, check out our 2018 video explaining how the system works."),
                            React.createElement("iframe", { className: 'video-frame', width: "560", height: "315", src: "https://www.youtube.com/embed/vJfgyfGIYYw", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }),
                            React.createElement("div", { className: 'content-description', style: { paddingTop: 20 } }, "Check out our latest news:"),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress April 2024")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/zhEhOT2c_I8?si=bL-lhNZTDKXiF_6s", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress December 2021")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/Jpivkqs2rg0", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress November 2021")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/D6gIu8nXlCQ", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress November 2021")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/SOnpwjbectw", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress November 2021")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/G3F7sRmonao", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/arysPG_8k4U", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }))),
                            React.createElement("div", { className: 'content-card' },
                                React.createElement("div", { className: 'content-card-header' },
                                    React.createElement("div", { className: 'content-card-title' }, "Mobotiq, work in progress")),
                                React.createElement("div", { className: 'content-card-body' },
                                    React.createElement("iframe", { className: 'video-frame', style: { paddingTop: 10 }, width: "560", height: "315", src: "https://www.youtube.com/embed/hCA4YSxmb-0", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true })))))))));
    }
}
export default UnderConstructionPage;
